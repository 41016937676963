var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.filteredTeachers.length),expression:"filteredTeachers.length"}],staticClass:"teacher-content"},[_c('div',{staticClass:"content-label"},[_c('span',[_vm._v("Gns. allokerede klokketimer for synlige:")]),_c('h2',[_vm._v(_vm._s(_vm._f("numeral")(_vm.percentageAllocatedHoursForView,'0,0.[00]'))+"%")])]),_vm._l((_vm.filteredTeachers),function(teacher,index){return _c('folding-panel',{key:index,attrs:{"title":teacher.title,"amount":_vm.hoursForTeacher(teacher),"max":_vm.teacherHoursForPlanning(teacher.id),"dragItemInfo":_vm.getTeacherDragInfo(teacher)},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.isClassSubjectView)?_c('div',[_c('v-multiselect',{staticClass:"small light",attrs:{"options":_vm.filteredClassSubjects,"multiple":false,"trackBy":"id","label":"classId","placeholder":"Ikke planlagte timer","select-label":"Tilføj"},on:{"select":function (classSubject, id) { return _vm.classSubjectSelected(classSubject, teacher, id); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_c('span',[_vm._v(_vm._s(("" + (option.classId))))])]}},{key:"singleLabel",fn:function(ref){
var option = ref.option;
var isOpen = ref.isOpen;
return [(!isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(("" + (option.classId)))+" ")]):_vm._e()]}}],null,true)})],1):(_vm.isProjectView)?_c('div',[_c('v-multiselect',{staticClass:"small light",attrs:{"options":_vm.projects,"multiple":false,"trackBy":"id","placeholder":"Ikke planlagte timer","select-label":"Tilføj","label":"title"},on:{"select":function (project, id) { return _vm.projectSelected(project, teacher, id); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_c('span',[_vm._v(_vm._s(("" + (option.id))))])]}},{key:"singleLabel",fn:function(ref){
var option = ref.option;
var isOpen = ref.isOpen;
return [(!isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(("" + (option.id)))+" ")]):_vm._e()]}}],null,true)})],1):_vm._e()]},proxy:true}],null,true)},[_vm._l((_vm.mergeProcessAllocationsForTeacher(teacher)),function(allocation,index){return _c('teacher-edit',{key:index,attrs:{"allocation":allocation}})})],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }