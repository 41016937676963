
















































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { ClassSubjectCriteriaModel } from '@/models/filterpanel/ClassSubjectCriteriaModel';
import { TeacherCriteriaModel } from '@/models/filterpanel/TeacherCriteriaModel';
import FoldingPanel from './FoldingPanel.vue';
import { ClassSubjectDto } from '@/models/planning/ClassSubjectDto';
import { TeacherDto } from '@/models/planning/TeacherDto';
import ClassSubjectTeacherEdit from '../editpanels/ClassSubjectTeacherEdit.vue';
import { DragItemInfo } from '@/models/core/DragItemInfo';

export default Vue.extend({
  name: 'classsubject-content',
  components: { FoldingPanel, ClassSubjectTeacherEdit },
  props: {
    classSubjectCriteriaModel: {
      type: Object as () => ClassSubjectCriteriaModel,
    },
    teacherCriteriaModel: {
      type: Object as () => TeacherCriteriaModel
    }
  },
  data: () => ({
    
  }),
  mounted() {
    this.registerDropListener({
      source:'teacher',
      target:'classsubject',
      action:(source:DragItemInfo, target:DragItemInfo) => {
        this.initTeacherOnSubjectAllocation({teacher:source.value, classSubject:target.value});
      }
    });
  },
  methods: {
    displayTeacher(teacher: TeacherDto) {
      return `${teacher.firstName} ${teacher.lastName}`;
    },
    ...mapActions('distributionStore', ['initTeacherOnSubjectAllocation']),
    ...mapActions('dragAndDropStore', ['registerDropListener']),
    teacherSelected(classSubject:ClassSubjectDto, teacher:TeacherDto) {
      this.initTeacherOnSubjectAllocation({teacher, classSubject});
    },
    classSubjectMax(classSubject:ClassSubjectDto) {
      return classSubject.relDepartmentNavigation.remote
        ? classSubject.numberOfStudentsExpected * classSubject.remoteStudentsToHoursFactor
        : classSubject.budgetPlanningHours * classSubject.subjectPlanningHoursFactor;
    },
    
  },
  computed: {
    ...mapGetters('classSubjectStore', ['mappedClassSubjects', 'classSubjectHoursForPlanning', 'classSubjectMergedClasses']),
    ...mapGetters('teachers', ['filteredTeachers']),
    ...mapGetters('distributionStore', ['allocationsForClassSubject', 'hoursForClassSubject']),
    ...mapGetters('dragAndDropStore', ['getClassSubjectDragInfo']),
  }
})
