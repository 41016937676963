































































































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { formatMultiselectEmit } from '@/models/core/FilterPanel';
import { ClassSubjectDto } from '@/models/planning/ClassSubjectDto';
import { DepartmentDto } from '@/models/planning/DepartmentDto';
import { EducationalDirectionDto } from '@/models/planning/EducationalDirectionDto';
import { SchoolSubjectDto } from '@/models/planning/SchoolSubjectDto';
import { ClassSubjectCriteriaModel } from '@/models/filterpanel/ClassSubjectCriteriaModel';

export default Vue.extend({
  name: 'classsubject-filterpanel',
  props: {
    filterSchoolTerm: {
      type: String,
      required: false
    },
    rootLoading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    selectedClassSubjects: null,
    selectedDepartments: null,
    selectedEducationalDirections: null,
    selectedSchoolSubjects: null,

  }),
  mounted() {
    this.emitSelection();
  },
  methods: {
    ...mapActions('classSubjectStore', ['filterClassSubjects']),
    emitSelection() {
      const value = {
        classSubjects: formatMultiselectEmit(this.selectedClassSubjects, (q: ClassSubjectDto) => q.classId),
        departments: formatMultiselectEmit(this.selectedDepartments, (q: DepartmentDto) => q.id),
        educationalDirections: formatMultiselectEmit(this.selectedEducationalDirections, (q: EducationalDirectionDto) => q.id),
        schoolSubjects: formatMultiselectEmit(this.selectedSchoolSubjects, (q: SchoolSubjectDto) => q.id)
      } as ClassSubjectCriteriaModel;
      
      this.filterClassSubjects(value);
    },
    clearSelection() {
      this.selectedClassSubjects = null;
      this.selectedDepartments = null;
      this.selectedEducationalDirections = null;
      this.selectedSchoolSubjects = null;
    }
  },
  computed: {
    ...mapGetters('classSubjectStore', ['classSubjects']),
    ...mapGetters('departments', ['departments']),
    ...mapGetters('educationDirectionsStore', {educationalDirections: 'educationDirections'}),
    ...mapGetters('schoolSubjectsStore', ['schoolSubjects']),
    educationalDirectionsForClassSubject() {
      return this.educationalDirections('classsubject');
    }
  },
  watch: {
    'filterSchoolTerm'() {
      // the quick way: clear selection if schoolTerm filter changes, since any selected class won't exist in the new term
      this.clearSelection();
    }
  }

})
