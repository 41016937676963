var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"classsubject-filterpanel filterpanel"},[_c('div',[_c('v-multiselect',{staticClass:"dark small",attrs:{"options":_vm.classSubjects,"loading":_vm.rootLoading,"multiple":true,"close-on-select":false,"show-labels":true,"reset-after":false,"placeholder":"Hold","label":"classId","trackBy":"classId","select-label":"Vælg","selected-label":"Valgt","deselect-label":"Fjern"},on:{"close":_vm.emitSelection},scopedSlots:_vm._u([{key:"tag",fn:function(){return [_c('div')]},proxy:true},{key:"selection",fn:function(ref){
var values = ref.values;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(values.length === 1 ? values[0].classId : ((values.length) + " hold"))+" ")]):_vm._e()]}},{key:"noOptions",fn:function(){return [(_vm.filterSchoolTerm)?_c('span',[_vm._v(" Det valgte semester"),_c('br'),_vm._v("har ingen hold ")]):_c('span',[_vm._v(" Semester er ikke valgt ")])]},proxy:true}]),model:{value:(_vm.selectedClassSubjects),callback:function ($$v) {_vm.selectedClassSubjects=$$v},expression:"selectedClassSubjects"}})],1),_c('div',[_c('v-multiselect',{staticClass:"dark small",attrs:{"options":_vm.departments,"loading":_vm.rootLoading,"multiple":true,"close-on-select":false,"show-labels":true,"placeholder":"Afd.","label":"name","trackBy":"id","select-label":"Vælg","selected-label":"Valgt","deselect-label":"Fjern"},on:{"close":_vm.emitSelection},scopedSlots:_vm._u([{key:"tag",fn:function(){return [_c('div')]},proxy:true},{key:"selection",fn:function(ref){
var values = ref.values;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(values.length === 1 ? values[0].name : ((values.length) + " afdelinger"))+" ")]):_vm._e()]}}]),model:{value:(_vm.selectedDepartments),callback:function ($$v) {_vm.selectedDepartments=$$v},expression:"selectedDepartments"}})],1),_c('div',[_c('v-multiselect',{staticClass:"dark small",attrs:{"options":_vm.educationalDirectionsForClassSubject,"loading":_vm.rootLoading,"multiple":true,"close-on-select":false,"show-labels":true,"placeholder":"Udd.ret.","label":"title","trackBy":"id","select-label":"Vælg","selected-label":"Valgt","deselect-label":"Fjern"},on:{"close":_vm.emitSelection},scopedSlots:_vm._u([{key:"tag",fn:function(){return [_c('div')]},proxy:true},{key:"selection",fn:function(ref){
var values = ref.values;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(values.length === 1 ? values[0].title : ((values.length) + " udd.ret."))+" ")]):_vm._e()]}}]),model:{value:(_vm.selectedEducationalDirections),callback:function ($$v) {_vm.selectedEducationalDirections=$$v},expression:"selectedEducationalDirections"}})],1),_c('div',[_c('v-multiselect',{staticClass:"dark small",attrs:{"options":_vm.schoolSubjects,"loading":_vm.rootLoading,"multiple":true,"close-on-select":false,"show-labels":true,"placeholder":"Fag","label":"title","trackBy":"id","select-label":"Vælg","selected-label":"Valgt","deselect-label":"Fjern"},on:{"close":_vm.emitSelection},scopedSlots:_vm._u([{key:"tag",fn:function(){return [_c('div')]},proxy:true},{key:"selection",fn:function(ref){
var values = ref.values;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(values.length === 1 ? values[0].title : ((values.length) + " fag"))+" ")]):_vm._e()]}}]),model:{value:(_vm.selectedSchoolSubjects),callback:function ($$v) {_vm.selectedSchoolSubjects=$$v},expression:"selectedSchoolSubjects"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }