












































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';
import { DragItemInfo } from '@/models/core/DragItemInfo';

export default Vue.extend({
  name: 'foldingpanel',
  props: {
    title: {
      type: String,
    },
    amount: {
      type: Number,
      default: 2
    },
    max: {
      type: Number,
      default: 3
    },
    dragItemInfo: {
      type: Object as () => DragItemInfo | undefined,
      required: false
    }
  },
  data: () => ({
    visible: false as Boolean,

  }),
  methods: {
    ...mapActions('dragAndDropStore', ['dragStart', 'dragEnd', 'drop', 'dragEnter', 'dragLeave']),
    toggleVisible() {
      this.visible = !this.visible;
    },
    show() {
      this.visible = true;
    }
  },
  computed: {
    ...mapGetters('dragAndDropStore', ['canDrop', 'dragOver', 'dragTarget', 'dragTargetHover']),
    overflow() {
      if (this.amount > this.max) {
        return this.amount - this.max;
      }
      return undefined;
    },
    amountPercent() {
      if (this.amount < this.max) {
        return 100 - (this.amount / this.max * 100);
      }
      return 0;
    },
    overflowPercent() {
      if (this.overflow) {
        return 100 - (this.overflow / (this.max + this.overflow) * 100);
      }
      return 100;
    },
    draggable() {
      return !!this.dragItemInfo;
    }
  }
  
})
