

























































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { formatMultiselectEmit } from '@/models/core/FilterPanel';
import { DepartmentDto } from '@/models/planning/DepartmentDto';
import { SchoolSubjectDto } from '@/models/planning/SchoolSubjectDto';
import { EducationalDirectionDto } from '@/models/planning/EducationalDirectionDto';

export default Vue.extend({
  name: 'teachers-filterpanel',
  props: {
    filterSchoolTerm: {
      type: String,
      required: false
    },
    rootLoading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    selectedDepartments: null,
    selectedEducationalDirections: null,
    selectedSchoolSubjects: null,
  }),
  methods: {
    ...mapActions('teachers', ['filterTeachers']),
    emitSelection() {
      const filter = {
        departments: formatMultiselectEmit(this.selectedDepartments, (q: DepartmentDto) => q.id),
        educationalDirections: formatMultiselectEmit(this.selectedEducationalDirections, (q: EducationalDirectionDto) => q.id),
        schoolSubjects: formatMultiselectEmit(this.selectedSchoolSubjects, (q: SchoolSubjectDto) => q.id)
      };
      
      this.filterTeachers(filter);
    },
    clearSelection() {
      this.selectedClassSubjects = null;
      this.selectedDepartments = null;
      this.selectedEducationalDirections = null;
      this.selectedSchoolSubjects = null;
    }

  },
  computed: {
    ...mapGetters('departments', ['departments']),
    ...mapGetters('schoolSubjectsStore', ['schoolSubjects']),
    ...mapGetters('educationDirectionsStore', ['educationDirections']),
    educationalDirectionsForTeacherSubject() {
      return this.educationDirections('teachersubject');
    }
  }

})
