





































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { TeacherCriteriaModel } from '@/models/filterpanel/TeacherCriteriaModel';
import { ProjectCriteriaModel } from '@/models/filterpanel/ProjectCriteriaModel';
import { ClassSubjectCriteriaModel } from '@/models/filterpanel/ClassSubjectCriteriaModel';
import FoldingPanel from './FoldingPanel.vue';
import { TeacherDto } from '@/models/planning/TeacherDto';
import { ProjectsDto } from '@/models/planning/ProjectsDto';
import { ClassSubjectDto } from '@/models/planning/ClassSubjectDto';
import { PlanningViewDto } from '@/models/ConstantsDto';
import TeacherEdit from '../editpanels/TeacherEdit.vue';
import { DragItemInfo } from '@/models/core/DragItemInfo';
import { AllocationsType } from '@/store/modules/planning/helpers/DataupdateHelper';

export default Vue.extend({
  name: 'teacher-content',
  components: { FoldingPanel, TeacherEdit },
  props: {
    projectCriteriaModel: {
      type: Object as () => ProjectCriteriaModel
    },
    teacherCriteriaModel: {
      type: Object as () => TeacherCriteriaModel
    },
    classSubjectCriteriaModel: {
      type: Object as () => ClassSubjectCriteriaModel
    },
    planningView: {
      type: Object as () => PlanningViewDto
    }
  },
  mounted() {
    this.registerDropListener({
      source: 'classsubject',
      target: 'teacher',
      action: (source:DragItemInfo, target:DragItemInfo) => {
        this.initTeacherOnSubjectAllocation({teacher:target.value, classSubject:source.value});
      }
    });
    this.registerDropListener({
      source: 'project',
      target: 'teacher',
      action: (source:DragItemInfo, target:DragItemInfo) => {
        this.initTeacherOnProjectAllocation({teacher:target.value, project:source.value});
      }
    });
  },
  methods: {
    ...mapActions('distributionStore', ['initTeacherOnSubjectAllocation', 'initTeacherOnProjectAllocation']),
    ...mapActions('dragAndDropStore', ['registerDropListener']),
    classSubjectSelected(classSubject:ClassSubjectDto, teacher:TeacherDto) {
      this.initTeacherOnSubjectAllocation({teacher, classSubject});
    },
    projectSelected(project:ProjectsDto, teacher:TeacherDto) {
      this.initTeacherOnProjectAllocation({teacher, project});
    },
    mergeProcessAllocationsForTeacher(teacher:TeacherDto):AllocationsType[] {
      const items = this.allocationsForTeacher(teacher);
      const filtered = items.filter(q => (q.relClassSubjectNavigation && !q.relClassSubjectNavigation.relMergedIntoClassSubject) || q.relProjectNavigation);
      return filtered;
    }
  },
  computed: {
    ...mapGetters('teachers', ['filteredTeachers']),
    ...mapGetters('projectsStore', ['projectsFiltered']),
    ...mapGetters('classSubjectStore', ['filteredClassSubjects']),
    ...mapGetters('distributionStore', ['allocationsForTeacher', 'hoursForTeacher']),
    ...mapGetters('teacherTermsStore', ['teacherHoursForPlanning']),
    ...mapGetters('dragAndDropStore', ['getTeacherDragInfo']),
    isProjectView() {
      return this.planningView.value === 'PROJECT';
    },
    isClassSubjectView() {
      return this.planningView.value === 'CLASSSUBJECT';
    },
    projects() {
      // if (this.projectCriteriaModel) {
      //   return this.projectsFiltered(this.projectCriteriaModel);
      // }
      return [];
    },
    averageAllocatedHoursForView() {
      const viewCount = this.filteredTeachers.length;
      const viewTotal = this.filteredTeachers
        .map((q:TeacherDto) => this.allocationsForTeacher(q).reduce((p,c)  => p + c.hours, 0))
        .reduce((previous:number, current:number) => previous + current, 0);
      const result = Math.round(viewTotal / viewCount);
      return isNaN(result) ? 0 : result;
    },
    percentageAllocatedHoursForView() {
      const viewTeacherHoursTotal = this.filteredTeachers.reduce((p:number,teacher:TeacherDto) => p + this.hoursForTeacher(teacher), 0);
      const viewTeacherHoursForPlanningTotal = this.filteredTeachers.reduce((p:number, teacher:TeacherDto) => p + this.teacherHoursForPlanning(teacher.id), 0);

      const result = (viewTeacherHoursTotal / viewTeacherHoursForPlanningTotal) * 100;
      return isNaN(result) ? 0 : result;
    }
  }
})
