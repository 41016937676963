



































































import SectionHeader from '@/components/SectionHeader.vue';
import Vue from 'vue';
import { planningViewOptions } from '@/repositories/ConstantsRepository';
import { PlanningViewDto } from '@/models/ConstantsDto';
import ClassSubjectFilterPanel from '@/views/distribution/filterpanels/ClassSubjectFilterPanel.vue';
import ClassSubjectContent from '@/views/distribution/contentpanels/ClassSubjectContent.vue';
import ProjectContent from '@/views/distribution/contentpanels/ProjectContent.vue';
import TeacherContent from '@/views/distribution/contentpanels/TeacherContent.vue';
import TeachersFilterPanel from '@/views/distribution/filterpanels/TeachersFilterPanel.vue';
import ProjectFilterPanel from '@/views/distribution/filterpanels/ProjectFilterPanel.vue';
import { ClassSubjectCriteriaModel, defaultClassSubjectCriteriaModel } from '@/models/filterpanel/ClassSubjectCriteriaModel';
import { TeacherCriteriaModel, defaultTeacherCriteriaModel } from '@/models/filterpanel/TeacherCriteriaModel';
import { defaultProjectCriteriaModel, ProjectCriteriaModel } from '@/models/filterpanel/ProjectCriteriaModel';
import { mapActions } from 'vuex';

export default Vue.extend({
  components: { SectionHeader, ClassSubjectFilterPanel, TeachersFilterPanel, ProjectFilterPanel, ClassSubjectContent, ProjectContent, TeacherContent },
  name: 'planning-view',
  props: {
    filterSchoolTerm: {
      type: String,
      required: false
    },
    rootLoading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    selectedPlanningView: null as PlanningViewDto,
    classSubjectCriteriaModel: defaultClassSubjectCriteriaModel as ClassSubjectCriteriaModel,
    teacherCriteriaModel: defaultTeacherCriteriaModel as TeacherCriteriaModel,
    projectCriteriaModel: defaultProjectCriteriaModel as ProjectCriteriaModel,

  }),
  methods: {
    ...mapActions("projectsStore", ["filterProjects"]),
    ...mapActions('classSubjectStore', ['filterClassSubjects']),
    updatedClassSubjectCriteria(value:ClassSubjectCriteriaModel) {
      this.classSubjectCriteriaModel = value;
    },
    updateProjectCriteria(value:ProjectCriteriaModel) {
      this.projectCriteriaModel = value;
    },
    updatedTeachersCriteria(value:TeacherCriteriaModel) {
      this.teacherCriteriaModel = value;
    },
    planningViewChange(value: { value: 'CLASSSUBJECT' | 'PROJECT'}) {
      if (value.value === "CLASSSUBJECT") {
        this.filterClassSubjects(this.classSubjectCriteriaModel);
      } else if (value.value === "PROJECT") {
        this.filterProjects(this.projectCriteriaModel);
      }
    }
  },
  computed: {
    planningViewOptions() {
      return planningViewOptions;
    },
    showClassSubjectPanels() {
      return this.selectedPlanningView?.value === 'CLASSSUBJECT';
    },
    showProjectPanels() {
      return this.selectedPlanningView?.value === 'PROJECT';
    }
  }

})
