

















































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { ProjectCriteriaModel } from '@/models/filterpanel/ProjectCriteriaModel';
import { TeacherCriteriaModel } from '@/models/filterpanel/TeacherCriteriaModel';
import FoldingPanel from './FoldingPanel.vue';
import { ProjectsDto } from '@/models/planning/ProjectsDto';
import { TeacherDto } from '@/models/planning/TeacherDto';
import ProjectTeacherEdit from '../editpanels/ProjectTeacherEdit.vue';
import { DragItemInfo } from '@/models/core/DragItemInfo';

export default Vue.extend({
  name: 'project-content',
  components: { FoldingPanel, ProjectTeacherEdit },
  props: {
    projectCriteriaModel: {
      type: Object as () => ProjectCriteriaModel,
    },
    teacherCriteriaModel: {
      type: Object as () => TeacherCriteriaModel
    }
  },
  mounted() {
    this.registerDropListener({
      source: 'teacher',
      target: 'project',
      action: (source:DragItemInfo, target:DragItemInfo) => {
        this.initTeacherOnProjectAllocation({teacher:source.value, project:target.value});
      }
    });
  },
  methods: {
    displayTeacher(teacher: TeacherDto) {
      return `${teacher.firstName} ${teacher.lastName}`;
    },
    ...mapActions('distributionStore', ['initTeacherOnProjectAllocation']),
    ...mapActions('dragAndDropStore', ['registerDropListener']),
    teacherSelected(project:ProjectsDto, teacher:TeacherDto) {
      this.initTeacherOnProjectAllocation({teacher, project});
    },
    projectMax(project:ProjectsDto) {
      return project.numberOfPlanningHours;
    },
    projectTypeEntriesFiltered(projectType) {
      return projectType.entries.sort((a,b) => {
        const aValue = `${a.id}`.toUpperCase();
        const bValue = `${b.id}`.toUpperCase();
        return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      });
    }
  },
  computed: {
    ...mapGetters('projectsStore', ['mappedProjects', 'projectsFiltered']),
    ...mapGetters('teachers', ['filteredTeachers']),
    ...mapGetters('distributionStore', ['allocationsForProject', 'hoursForProject']),
    ...mapGetters('dragAndDropStore', ['getProjectDragInfo']),
  }

})
