

























































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { formatMultiselectEmit, formatSingleselectEmit } from '@/models/core/FilterPanel';
import { DepartmentDto } from '@/models/planning/DepartmentDto';
import { ProjectTypeDto } from '@/models/planning/ProjectTypeDto';
import { FinancedDto } from '@/models/ConstantsDto';

export default Vue.extend({
  name: 'project-filterpanel',
  props: {
    filterSchoolTerm: {
      type: String,
      required: false
    },
    rootLoading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    selectedDepartments: [],
    selectedProjectTypes: [],
    selectedFinancedOption: undefined,

  }),
  methods: {
    ...mapActions("projectsStore", ["filterProjects"]),
    emitSelection() {
      const value = {
        departments: formatMultiselectEmit(this.selectedDepartments, (q: DepartmentDto) => q.id),
        projectTypes: formatMultiselectEmit(this.selectedProjectTypes, (q: ProjectTypeDto) => q.id),
        financedOption: formatSingleselectEmit(this.selectedFinancedOption, (q: FinancedDto) => q.value)
      };

      this.filterProjects(value);
    },
    clearSelection() {
      this.selectedDepartments = null;
      this.selectedProjectTypes = null;
      this.selectedFinancedOption = null;
    },

  },
  computed: {
    ...mapGetters('departments', ['departments']),
    ...mapGetters('projectTypeStore', ['projectTypes']),
    ...mapGetters('constantsStore', ['financedOptions']),

  },
  watch: {
    'filterSchoolTerm'() {
      this.clearSelection();
    }
  }
})
