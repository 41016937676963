





























import { TeacherOnProjectAllocationDto } from '@/models/planning/TeacherOnProjectAllocationDto';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import APISTATE from '@/enums/APISTATE';

export default Vue.extend({
  name: 'projectteacher-edit',
  props: {
    teacherOnProjectAllocation: {
      type: Object as () => TeacherOnProjectAllocationDto,
    }
  },
  data: () => ({
    hours: 0 as number,
    percentage: 0,
    state: '0',
  }),
  mounted() {
    this.updateInputFields();
  },
  methods: {
    ...mapActions('distributionStore', ['updateTeacherOnProjectAllocation']),
    updateInputFields() {
      this.percentage = (this.hoursForPlanning === 0) ? 0 : Math.round((this.teacherOnProjectAllocation.hours / this.hoursForPlanning * 100) * 100) / 100;
      this.hours = this.teacherOnProjectAllocation.hours;
    },
    async percentageUpdated() {
      this.hours = Math.round((this.hoursForPlanning * this.percentage / 100) * 100) / 100;
      await this.updateAllocation();
    },
    async hoursUpdated() {
      this.percentage = (this.hoursForPlanning === 0) ? 0 : Math.round((this.hours / this.hoursForPlanning * 100) * 100) / 100;
      await this.updateAllocation();
    },
    async updateAllocation() {
      try {
        const allocation = {
          relTeacher: this.teacherOnProjectAllocation.relTeacher,
          relProject: this.teacherOnProjectAllocation.relProject,
          state: this.state,
          hours: this.inputHours
        } as TeacherOnProjectAllocationDto;
        await this.updateTeacherOnProjectAllocation(allocation);
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved opdatering af allokering.',
          type: 'is-danger'
        });
      }
    },

  },
  computed: {
    ...mapGetters('projectsStore', ['projectHoursForPlanning']),
    ...mapGetters('distributionStore', ['distributionApiState']),
    teacherName() {
      const initials = `${this.teacherOnProjectAllocation.relTeacherNavigation.initials}`.toUpperCase();
      const name = `${this.teacherOnProjectAllocation.relTeacherNavigation.firstName} ${this.teacherOnProjectAllocation.relTeacherNavigation.lastName}`;
      return `${initials} - ${name}`;
    },
    hoursForPlanning() {
      return this.projectHoursForPlanning(this.teacherOnProjectAllocation.relProject);
    },
    loading() {
      return this.distributionApiState === APISTATE.LOADING;
    },
    inputHours() {
      if (this.hours) {
        return parseFloat(this.hours);
      }
      return 0;
    },
    inputPercentage() {
      if (this.percentage) {
        return parseFloat(this.percentage);
      }
      return 0;
    }

  },
  watch: {
    'teacherOnProjectAllocation': {
      deep: true,
      handler() {
        this.updateInputFields();
      }
    },

  }
})
